// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/home/gitlab-runner/builds/MW9Q6uNE/0/manual/manual.dif-digitaldefenders.org/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-directory-js": () => import("/home/gitlab-runner/builds/MW9Q6uNE/0/manual/manual.dif-digitaldefenders.org/src/pages/directory.js" /* webpackChunkName: "component---src-pages-directory-js" */),
  "component---src-pages-guides-js": () => import("/home/gitlab-runner/builds/MW9Q6uNE/0/manual/manual.dif-digitaldefenders.org/src/pages/guides.js" /* webpackChunkName: "component---src-pages-guides-js" */),
  "component---src-pages-index-js": () => import("/home/gitlab-runner/builds/MW9Q6uNE/0/manual/manual.dif-digitaldefenders.org/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("/home/gitlab-runner/builds/MW9Q6uNE/0/manual/manual.dif-digitaldefenders.org/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */)
}

exports.data = () => import("/home/gitlab-runner/builds/MW9Q6uNE/0/manual/manual.dif-digitaldefenders.org/.cache/data.json")

